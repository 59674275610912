


























































import Vue from 'vue'
import { ApiResponse, Structure } from '@/interfaces'
import { mapState } from 'vuex'

interface SendData {
  structure?: number | null
  account?: boolean | null
  type?: string | null
}

interface StructureSelect {
  id: number | null
  name: string | null
  type: string | null
}

export default Vue.extend({
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    saleId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      busy: false,
      visibleModal: false,
      structures: [] as StructureSelect[],
      sendData: {} as SendData,
    }
  },
  computed: {
    ...mapState(['user']),
    modalTitle() {
        return 'Synthèse chiffrée après vente'
    },
  },
  watch: {
    user(newVal) {
      if (Array.isArray(newVal.client.structures)) {
        this.structures = []
        newVal.client.structures.forEach((structure: Structure) => {
          this.structures.push({
            id: structure.id ?? null,
            name: structure.name ?? null,
            type: structure.type ?? null,
          })
        })

        // If there's only one structure, it should be selected by default
        if (this.structures.length == 1) {
          this.sendData.structure = this.structures[0].id
        }
      }
    },
    showModal(newVal) {
      this.visibleModal = newVal
    },
  },
  mounted() {
    if (this.user) {
      // Update structures
      this.structures = this.user.client.structures

      // If there's only one structure, it should be selected by default
      if (this.structures.length == 1) {
        this.sendData.structure = this.structures[0].id
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
    noSubmit() {
      return false
    },
    submit() {
      //this.$set(this.sendData, 'structure', this.recipients)
      //this.$emit('submit', this.sendData)
      this.$emit('closeDialog')
      this.generateAfterSaleSynthesis(false)
    },
    disabledForm() {
      return false
    },
    generateAfterSaleSynthesis(accountData: boolean) {
      if (!this.busy) {
        this.busy = true

        this.$api
          .post(`/sale/${this.saleId}/after-sale-synthesis?accountData=${accountData}`, this.sendData)
          .then((response) => {
            const apiResponse = response.data as ApiResponse
            const documentUrl = `${process.env.VUE_APP_API_URL}/document/${apiResponse.data.filename}/download_once?contentd=inline`
            window.open(documentUrl, '_blank')
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
  },
})
