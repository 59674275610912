


























import { ApiResponse, Document, Sale } from '@/interfaces'
import Vue from 'vue'
import DocumentsList from '@/components/DocumentsList.vue'
import DocumentsUpload from '@/components/DocumentsUpload.vue'

export default Vue.extend({
  components: { DocumentsList, DocumentsUpload },
  props: {
    sale: {
      type: Object as () => Sale,
      required: false,
      default: {},
    },
    activeTab: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      documents: [] as Document[],
      docToUpdate: null as null | Document,
      docType: '' as string | null,
    }
  },
  watch: {
    sale(newVal) {
      if (Number.isInteger(newVal.id) && this.activeTab === 'docs') {
        this.getDocuments()
      }
    },
    activeTab(newVal) {
      if (newVal === 'docs' && Number.isInteger(this.sale.id)) {
        this.getDocuments()
      }
    },
  },
  mounted() {
    if (this.sale.id && this.activeTab === 'docs') {
      this.getDocuments()
    }
  },
  methods: {
    getDocuments() {
      if (this.sale?.id !== undefined && this.documents.length === 0) {
        this.$api(`/sale/${this.sale?.id}/documents`).then((response) => {
          const apiResponse = response.data as ApiResponse

          this.documents = apiResponse.data
        })
      }
    },
    refreshDocuments(documents: Document[]) {
      this.documents = documents
    },
    updateDoc(document: Document) {
      this.docToUpdate = document
      ;(this.$refs.uploadDoc as InstanceType<typeof DocumentsUpload>).addFile()
    },
    refreshSaleItems() {
      this.$emit('refreshSaleItems')
    },
  },
})
